import React from "react"
import { Link } from "gatsby"

import {
  Layout,
  Container,
  SEO,
  Sheet,
  Subscription,
  ThinContainer,
} from "../components/common"

import "./license.css"

export default ({
  pageContext: { totalItems, stats, defaultGradient, footerGradient },
}) => (
  <Layout
    title="License"
    totalItems={totalItems}
    stats={stats}
    defaultGradient={defaultGradient}
    footerGradient={footerGradient}
  >
    <Container>
      <SEO title="License" />
      <Sheet>
        <ThinContainer>
          <div className="license-details">
            <h6>
              Codioful images are free for personal and commercial use. However,
              please provide a credit/attribution link back to the page where
              you have downloaded an image. By doing this, you are helping and
              supporting Codioful to grow.
            </h6>
            <p>
              That means, all of the gradients either CSS codes, JPG, SVG or PNG
              files are totally free. You can use them in your personal and
              commercial projects including but not limited to,
            </p>
            <ul>
              <li>Themes (Android/iOS Themes, WordPress Themes, etc)</li>
              <li>Plugins, Extensions, Addons</li>
              <li>Graphic Templates, Plugins, etc</li>
              <li>Any Kind of User Interfaces (Web, Mobile, etc)</li>
              <li>Social Media Banners</li>
              <li>Brochures, Posters, Books, etc</li>
              <li>T-shirts or Cloths</li>
              <li>Print On Demand (POD) sites</li>
              <li>Logos</li>
              <li>Any product where end users may or may not be charged</li>
            </ul>
            <p>
              If you have any questions, ideas, suggestions or bugs about the
              license or the website please feel free to{" "}
              <Link to="/contact">contact me</Link>.
            </p>
            <p>
              Copyright ©2019 - 2020 Shahadat Rahman
              <br />
              <br />
              Permission is hereby granted, free of charge, to any person
              obtaining a copy of this software and associated documentation
              files (the "Software"), to deal in the Software without
              restriction, including without limitation the rights to use, copy,
              modify, merge, publish, distribute, sublicense, and/or sell copies
              of the Software, and to permit persons to whom the Software is
              furnished to do so, subject to the following conditions:
              <br />
              <br />
              The above copyright notice and this permission notice shall be
              included in all copies or substantial portions of the Software.
              <br />
              <br />
              THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
              EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
              NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
              HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
              WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
              OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
              DEALINGS IN THE SOFTWARE.
            </p>
          </div>
        </ThinContainer>
      </Sheet>
      <Subscription />
    </Container>
  </Layout>
)
